import React, { useRef, useState } from "react";
import DoctorCard from "./DoctorCard";
import "./ChooseDoctor.css";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { doctors } from "../department/departmentData";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import useGetAllDoctors from "../../../hooks/useGetAllDoctors";


const ChooseDoctor = () => {
  const { t } = useTranslation();
  const words = t("chooseDoctor.title")?.split(" ");
  const firstPart = words.slice(0, 1).join(" ");
  const secondPart = words[1];
  const thirdPart = words.slice(3, words.length).join(" ");
  const  {doctors} = useGetAllDoctors()

  //scroll
  const scrollContainer = useRef();
  const scrollLeft = () => {
    scrollContainer?.current?.scrollBy({
      left: -383,
      behavior: "smooth",
    });
  };
  const scrollRight = () => {
    scrollContainer?.current?.scrollBy({
      left: 383,
      behavior: "smooth",
    });
  };

  return (
    <div className="md:py-20 py-10 flex flex-col items-center">
      {/* Heading part */}
      <div className="relative max-w-common mx-auto p-3 flex max-md:flex-col max-md:gap-5 w-full items-center justify-between">
        <div className=" flex flex-col items-start">
          <h1 className="font-bold text-2xl md:text-headings font-sans capitalize">
            {firstPart}
            <span className="text-primary_color"> {secondPart} </span>
          </h1>

          <h6 className="mt-2 md:text-xl font-newFont font-thin">
            {t("chooseDoctor.subTitle")}
          </h6>
        </div>
        <div>
          <Link to={"/our-team"}>
            <h4 className="border-2 border-primary_color p-2 px-5 rounded text-xs text-primary_colors hover:bg-primary_color ease-in-out duration-300 hover:text-white cursor-pointer">
              {t("seeMore")}
            </h4>
          </Link>
        </div>
      </div>

      <div className="w-screen flex relative">
        <div
          className="cursor-pointer w-[40px] p-3 h-fit absolute top-[50%] left-3 md:left-14 rounded-full bg-primary_color  text-white outline-none focus:outline-none z-20"
          onClick={scrollLeft}
        >
          <IoIosArrowBack />
        </div>
        <div
          ref={scrollContainer}
          className="flex w-full overflow-x-scroll pl-10 max-md:px-[25px] hide-scrollbar relative"
        >
          <div className="w-fit flex  mt-6 gap-x-16 md:ps-24">
            {doctors.slice(0, 10).map((items) => (
              <DoctorCard
                key={items?.id}
                Name={items?.doctor_name}
                department={items?.department_name}
                image={items?.image?.location}
                data={items}
              />
            ))}
          </div>
        </div>
        <div
          className="cursor-pointer w-[40px] h-fit absolute top-[50%] right-3 md:right-14 rounded-full bg-primary_color p-3 text-white outline-none z-10"
          onClick={scrollRight}
        >
          <IoIosArrowForward />
        </div>
      </div>

      
    </div>
  );
};

export default ChooseDoctor;
