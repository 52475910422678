import React from 'react'

const MissionAndVision = ({title , description}) => {
  return (
    <div className='m-6'>
      <div className='max-md:w-full h-fit flex flex-col items-start p-8 max-md:p-7 bg-white rounded-xl shadow-[-21px_21px_70px_0px_rgba(0,0,0,0.3)] gap-y-3'>
        <h1 className="text-2xl text-primary_color max-md:text-xl max-md:text-left">
          <span className="font-semibold">{title}</span> 
        </h1>
        <p className="text-left text-lg max-md:text-base font-light ">
          {description}
        </p>
      </div>
    </div>
  )
}

export default MissionAndVision
