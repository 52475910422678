import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Modal, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

const DoctorCard = ({ Name, department, image, data }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const ServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const PublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const RecaptchaKey = process.env.REACT_APP_G_SITE_KEY;

  useEffect(() => {
    emailjs.init(PublicKey);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "Booking",
    message: `
    Doctor Name: ${Name},
    Department: ${department},
    `,
  });

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      return toast.error("Please complete the reCAPTCHA");
    }
    setLoading(true);

    emailjs
      .sendForm(ServiceId, TemplateId, e.target, PublicKey)
      .then((result) => {
        console.log("Email sent successfully!");

        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        toast.success("Message Send Successfully");
        setLoading(false);
        setOpen(false);
        console.log("Form submitted:", result);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        toast.error("Please try after sometime");
        setLoading(false);
      });
  };

  // for the modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: 500,
    borderRadius: 2,
    p: 4,
    "@media (max-width: 768px)": {
      width: "90vw",
    },
  };

  return (
    <>
      <div className="w-[320px] relative bg-center">
        <div className="h-[380px] w-[320px] z-0  absolute mt-14 rounded-t-[2.5rem] shadow-[0_0px_60px_-15px_rgba(0,0,0,0.3)]"></div>
        <Link to={`/doctor-profile/${data?._id}`}>
          <img
            src={image}
            alt=""
            className=" h-[340px] w-[320px] object-cover  relative rounded-lg mt-5 z-10"
          />
        </Link>
        <div className="h-[195px] flex flex-col justify-between bg-[#852065] z-10 relative p-[25px] rounded-b-[2.5rem] shadow-[0_10px_60px_-15px_rgba(0,0,0,0.3)] mb-14">
          <span>
            <h4 className="text-start text-2xl text-white font-semibold capitalize  ">
              {Name}
            </h4>
            <h6 className="text-start text-white text-lg font-light truncate">
              {department}
            </h6>
          </span>
          <Link to={`/doctor-profile/${data._id}`}>
            <button className="bg-white py-3 w-full mt-4  rounded-full text-sm font-semibold text-primary_color hover:scale-105 ease-in-out duration-300">
              Book an Appointment
            </button>
          </Link>
        </div>
      </div>
      {/* {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex flex-col ">
              <h4 className="text-sm font-medium text-primary_color">
                Contact Info
              </h4>
              <p className="text-lg  font-medium">
                Please enter your details to finish booking{" "}
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-y-4 mt-5"
              >
                <TextField
                  size="small"
                  id="outlined-search"
                  label="Full name"
                  type="name"
                  name="name"
                  onChange={handleChange}
                  value={formData?.name}
                />
                <TextField
                  size="small"
                  id="outlined-search"
                  label="Email"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={formData?.email}
                />
                <TextField
                  size="small"
                  id="outlined-search"
                  label="Phone Number"
                  type="phone"
                  name="phone"
                  onChange={handleChange}
                  value={formData?.phone}
                />

                {/* Add the reCAPTCHA component */}
                {/* <ReCAPTCHA
                  sitekey={RecaptchaKey}
                  onChange={handleRecaptchaChange}
                />
                
                <button className="border-none px-5 py-2 rounded-lg text-sm text-white bg-primary_color  w-fit">
                  Submit
                </button>
              </form>
              <div className="h-[1px] bg-gray-700 w-full my-5"></div>
              <a
                onClick={() => setOpen(false)}
                href="tel: +91 0471 3100 100"
                className="w-full"
              >
                <button className="border-none w-full px-4 py-3 rounded-lg text-sm text-white bg-primary_color font-medium">
                  <span className=""> Call +91 471 3100 100</span>
                </button>
              </a>
            </div>
          </Box>
        </Modal>
      } */}
    </>
  );
};

export default DoctorCard;
